*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-decoration: none;
  font-family: Robot, sans-serif !important;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-section .floors-select--block-item--active {
  color: #fff;
}

.custom-cursor {
  --cursor-x: -10;
  --cursor-y: -10;
  transform: translate(calc(var(--cursor-x) * 1px), calc(var(--cursor-y) * 1px));
  pointer-events: none;
  z-index: 99999;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media (any-pointer: coarse) {
  .custom-cursor {
    display: none;
  }
}

.custom-cursor__dot {
  background: none;
  border: 9px solid #fff9;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  position: absolute;
  top: -27px;
  left: -27px;
}

.additional-text {
  letter-spacing: -.05em;
  color: #414141;
  z-index: 999;
  font-family: Roboto Mono;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  bottom: 5px;
  right: 12px;
}

@media screen and (width <= 715px) {
  .additional-text {
    font-size: 15px;
    line-height: 90%;
    bottom: 2px;
    right: 12px;
  }
}

@media screen and (width <= 515px) {
  .additional-text {
    letter-spacing: -.07em;
    font-size: 12px;
  }
}

@media screen and (width <= 390px) {
  .additional-text {
    font-size: 10px;
    bottom: 4px;
  }
}

.floors-select {
  top: 67px !important;
}

@media screen and (width <= 365px) {
  .floors-select {
    left: unset !important;
    top: 20px !important;
    transform: translateX(0) !important;
  }
}

.widget-enter-fullscreen {
  top: 70px !important;
  right: 2px !important;
}

@media screen and (width <= 1025px) {
  .widget-enter-fullscreen {
    top: 68px !important;
  }
}

.widget-enter-fullscreen img {
  width: 46px !important;
  height: 46px !important;
}

@media screen and (width <= 1025px) {
  .widget-enter-fullscreen img {
    width: 40px !important;
    height: 40px !important;
  }
}

.widget-instructions__step p {
  letter-spacing: -.04em !important;
  max-width: 205px !important;
  margin-top: 14px !important;
  font-family: Robot, sans-serif !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 37px !important;
}

.widget-watermark-button {
  display: none !important;
}

.widget-tab__panorama-map {
  bottom: 55px !important;
  right: 12px !important;
  left: unset !important;
}

.widget-tab__panorama-controls {
  display: none !important;
}

.widget-tab__panorama-controls-button--ruler {
  background: #fff !important;
  width: 52px !important;
  height: 52px !important;
  margin-right: 0 !important;
  display: none !important;
}

.widget-application--mobile .widget-tab__panorama-controls-button--ruler {
  width: 36px !important;
  height: 36px !important;
}

.widget-tab__panorama-controls-button--ruler img {
  width: 24px !important;
  height: 24px !important;
}

@media (width <= 1100px) {
  .widget-tab__panorama-controls-button--ruler img {
    width: 34px !important;
    height: 34px !important;
  }
}

.widget-tab__panorama-controls-button--scale {
  background: #fff !important;
  width: 52px !important;
  height: 52px !important;
  margin-right: 0 !important;
}

.widget-application--mobile .widget-tab__panorama-controls-button--scale {
  width: 36px !important;
  height: 36px !important;
}

.widget-tab__panorama-controls-button--scale img {
  width: 50px !important;
  height: 50px !important;
  display: block !important;
}

.widget-tab__panorama-controls-button--scale span {
  letter-spacing: -.1em !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
}

@media (width <= 670px) {
  .widget-tab__panorama-controls-button--scale span {
    font-size: 13px !important;
    line-height: 17px !important;
  }
}

.widget-tab__panorama-map {
  right: 45px;
  left: unset !important;
}

.widget-tab__panorama-map:before {
  left: unset !important;
  right: 50% !important;
  transform: translate(50%, -50%) !important;
}

.widget-application--tablet .widget-tab__panorama-map {
  transform-origin: 100% 100% !important;
  right: 35px !important;
}

.widget-application--mobile .widget-tab__panorama-map {
  bottom: 25px !important;
  right: 22px !important;
}

.widget-application--smallMobile .widget-tab__panorama-map {
  bottom: 80px !important;
  right: 15px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__panorama-map {
    bottom: 1.75rem !important;
    right: 2.25rem !important;
    transform: scale(1.3)translate(-20%, -20%) !important;
  }
}

@media screen and (width >= 2560px) {
  .widget-tab__panorama-map {
    transform: scale(1.6)translate(-25%, -25%) !important;
  }
}

@media screen and (width >= 2960px) {
  .widget-tab__panorama-map {
    transform: scale(2)translate(-25%, -30%) !important;
  }
}

@media screen and (width >= 5000px) {
  .widget-tab__panorama-map {
    transform: scale(2.5)translate(-30%, -35%) !important;
  }
}

.widget-application--tablet .widget-tab__panorama-map--scaled {
  top: 60% !important;
  left: 60% !important;
  right: unset !important;
  bottom: unset !important;
  transform: scale(1.4)translate(-50%, -50%) !important;
}

.widget-application--mobile .widget-tab__panorama-map--scaled {
  top: 50% !important;
  left: 50% !important;
  transform: scale(1)translate(-50%, -50%) !important;
}

.widget-tab-buttons {
  border-radius: 60px;
  transform: translateX(-50%);
  left: 50% !important;
  right: unset !important;
  background: #41414166 !important;
  justify-content: flex-start !important;
  column-gap: 8px !important;
  max-width: 438px !important;
  padding: 8px !important;
  bottom: 55px !important;
}

.widget-application--mobile .widget-tab-buttons {
  padding: 6px !important;
  left: 12px !important;
  transform: none !important;
}

@media (width <= 870px) {
  .widget-tab-buttons {
    left: 12px !important;
    transform: translateX(0) !important;
  }
}

@media (width <= 670px) {
  .widget-tab-buttons {
    bottom: 25px !important;
  }
}

.widget-tab-button {
  background-color: #fff !important;
  width: 52px !important;
  height: 52px !important;
  margin-left: 0 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.widget-application--mobile .widget-tab-button {
  width: 36px !important;
  height: 36px !important;
  font-size: 13px !important;
  line-height: 17px !important;
}

.widget-tab-button--active {
  background-color: #414141 !important;
}

.widget-tab__isometry-buttons {
  right: 45px !important;
  left: unset !important;
  bottom: 63px !important;
}

@media (width <= 670px) {
  .widget-tab__isometry-buttons {
    bottom: 31px !important;
    right: 12px !important;
  }
}

@media (width <= 315px) {
  .widget-tab__isometry-buttons {
    bottom: 80px !important;
  }
}

.widget-application--mobile .widget-tab__isometry {
  right: 25px !important;
}

.widget-tab__isometry-button {
  left: unset !important;
  width: 52px !important;
  height: 52px !important;
  bottom: 29px !important;
}

@media (width <= 670px) {
  .widget-tab__isometry-button {
    width: 36px !important;
    height: 36px !important;
    bottom: 29px !important;
  }
}

@media (width <= 375px) {
  .widget-tab__isometry-button {
    width: 36px !important;
    height: 36px !important;
    bottom: 29px !important;
  }
}

.widget-tab__isometry-arrows {
  left: unset !important;
  bottom: unset !important;
  width: 107px !important;
  height: 52px !important;
  position: relative !important;
  transform: none !important;
}

.widget-application--mobile .widget-tab__isometry-arrows {
  width: 74px !important;
  height: 36px !important;
}

.widget-tab__isometry-arrows:before {
  background-color: #fff !important;
  width: 100% !important;
}

.widget-tab__isometry-arrows span {
  margin: 0 4px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.widget-application--mobile .widget-tab__isometry-arrows span {
  margin: 0 !important;
  font-size: 13px !important;
  line-height: 20px !important;
}

.widget-tab__isometry-arrows img {
  width: 20px !important;
  height: 20px !important;
}

.widget-application--mobile .widget-tab__isometry-arrows img {
  width: 15px !important;
  height: 15px !important;
}

.widget-application > .floors-select {
  display: none;
}

.floors-select {
  justify-content: center;
  align-items: center;
  display: flex;
  top: unset !important;
  left: unset !important;
  background: #fff !important;
  border-bottom: none !important;
  border-radius: 30px !important;
  width: 122px !important;
  height: 52px !important;
  position: relative !important;
  transform: none !important;
}

@media (width <= 670px) {
  .floors-select {
    width: 72px !important;
    height: 36px !important;
    padding: 8px 16px !important;
  }
}

.floors-select--text {
  word-spacing: 0 !important;
  border-bottom: 0 !important;
  padding: 0 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

@media (width <= 670px) {
  .floors-select--text {
    font-size: 13px !important;
    line-height: 20px !important;
  }
}

.floors-select--block {
  background: #fff !important;
  border-radius: 15px !important;
  flex-direction: column-reverse !important;
  position: absolute !important;
  bottom: 60px !important;
  right: 0 !important;
}

.floors-select--block-item {
  word-spacing: 0 !important;
  padding: 14px 22px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

@media (width <= 670px) {
  .floors-select--block-item {
    padding: 8px 16px !important;
    font-size: 13px !important;
    line-height: 20px !important;
  }
}
/*# sourceMappingURL=index.f3daef42.css.map */
