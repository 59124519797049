*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: Robot, sans-serif !important
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
  .floors-select--block-item--active
    color: #FFFFFF


.custom-cursor
  --cursor-x: -10
  --cursor-y: -10
  position: fixed
  left: 0
  top: 0
  transform: translate(calc(var(--cursor-x) * 1px), calc(var(--cursor-y) * 1px))
  pointer-events: none
  z-index: 99999
  display: none
  @media (any-pointer: coarse)
    display: none
  &__dot
    position: absolute
    left: -27px
    top: -27px
    width: 54px
    height: 54px
    border-radius: 50%
    border: 9px solid rgba(255, 255, 255, 0.6)
    background: none

.additional-text
  position: absolute
  bottom: 5px
  right: 12px
  font-family: Roboto Mono
  font-size: 20px
  font-weight: 400
  line-height: 18px
  letter-spacing: -0.05em
  color: rgba(65, 65, 65, 1)
  z-index: 999
  @media screen and (max-width: 715px)
    bottom: 2px
    right: 12px
    font-size: 15px
    line-height: 90%
  @media screen and (max-width: 515px)
    font-size: 12px
    letter-spacing: -0.07em
  @media screen and (max-width: 390px)
    bottom: 4px
    font-size: 10px

.floors-select
  top: 67px !important
  @media screen and (max-width: 365px)
    left: unset !important
    top: 20px !important
    transform: translateX(0) !important

.widget-enter-fullscreen
  top: 70px !important
  right: 2px !important
  @media screen and (max-width: 1025px)
    top: 68px !important
  img
    width: 46px !important
    height: 46px !important
    @media screen and (max-width: 1025px)
      width: 40px !important
      height: 40px !important

.widget-instructions
  &__step 
    p
      font-family: Robot, sans-serif !important
      font-size: 26px !important
      font-weight: 700 !important
      line-height: 37px !important
      max-width: 205px !important
      letter-spacing: -0.04em !important
      margin-top: 14px !important

.widget-watermark-button
  display: none !important

.widget-tab__panorama-map
  bottom: 55px !important
  right: 12px !important
  left: unset !important
  
.widget-tab__panorama-controls
  display: none !important
  &-button
    &--ruler
      display: none !important
      background: rgba(255, 255, 255, 1) !important
      width: 52px !important
      height: 52px !important
      margin-right: 0 !important
      @at-root .widget-application--mobile &
        width: 36px !important
        height: 36px !important
      img
        width: 24px !important
        height: 24px !important
        @media (max-width: 1100px)
          width: 34px !important
          height: 34px !important
    &--scale
      background: rgba(255, 255, 255, 1) !important
      width: 52px !important
      height: 52px !important
      margin-right: 0 !important
      @at-root .widget-application--mobile &
        width: 36px !important
        height: 36px !important
      img
        display: block !important
        width: 50px !important
        height: 50px !important
        @media (max-width: 1100px)
      span
        font-size: 20px !important
        font-weight: 400 !important
        line-height: 23px !important
        letter-spacing: -0.1em !important
        @media (max-width: 670px)
          font-size: 13px !important
          line-height: 17px !important

.widget-tab__panorama-map
  left: unset !important
  right: 45px
  &::before
    left: unset !important
    right: 50% !important
    transform: translate(50%, -50%) !important
  @at-root .widget-application--tablet &
    right: 35px !important
    transform-origin: 100% 100% !important
  @at-root .widget-application--mobile &
    right: 22px !important
    bottom: 25px !important
  @at-root .widget-application--smallMobile &
    right: 15px !important
    bottom: 80px !important
  @media screen and (min-width: 2200px)
    bottom: 1.75rem !important
    right: 2.25rem !important
    transform: scale(1.3) translate(-20%, -20%) !important
  @media screen and (min-width: 2560px)
    transform: scale(1.6) translate(-25%, -25%) !important
  @media screen and (min-width: 2960px)
    transform: scale(2) translate(-25%, -30%) !important
  @media screen and (min-width: 5000px)
    transform: scale(2.5) translate(-30%, -35%) !important
  &--scaled
    @at-root .widget-application--tablet &
      left: 60% !important
      top: 60% !important
      right: unset !important
      bottom: unset !important
      transform: scale(1.4) translate(-50%, -50%) !important
    @at-root .widget-application--mobile &
      left: 50% !important
      top: 50% !important
      transform: scale(1) translate(-50%, -50%) !important

.widget-tab
  &-buttons
    left: 50% !important
    justify-content: flex-start !important
    column-gap: 8px !important
    right: unset !important
    column-gap: 8px !important
    bottom: 55px !important
    background: rgba(65, 65, 65, 0.4) !important
    padding: 8px !important
    max-width: 438px !important
    border-radius: 60px
    transform: translateX(-50%)
    @at-root .widget-application--mobile &
      left: 12px !important
      padding: 6px !important
      transform: none !important
    @media (max-width: 870px)
      left: 12px !important
      transform: translateX(0) !important
    @media (max-width: 670px)
      bottom: 25px !important
  &-button
    margin-left: 0 !important
    background-color: rgba(255, 255, 255, 1) !important
    font-size: 20px !important
    line-height: 28px !important
    font-weight: 400 !important
    width: 52px !important
    height: 52px !important
    @at-root .widget-application--mobile &
      font-size: 13px !important
      line-height: 17px !important
      width: 36px !important
      height: 36px !important
    &--active
      background-color: rgba(65, 65, 65, 1) !important
  &__isometry
    &-buttons
      right: 45px !important
      left: unset !important
      bottom: 63px !important
      @media (max-width: 670px)
        bottom: 31px !important
        right: 12px !important
      @media (max-width: 315px)
        bottom: 80px !important
    @at-root .widget-application--mobile &
      right: 25px !important
    &-button
      width: 52px !important
      height: 52px !important
      // background-color: DFDFDF !important
      left: unset !important
      bottom: 29px !important
      @media (max-width: 670px)
        bottom: 29px !important
        width: 36px !important
        height: 36px !important
      @media (max-width: 375px)
        width: 36px !important
        height: 36px !important
        bottom: 29px !important
    &-arrows
      position: relative !important
      left: unset !important
      bottom: unset !important
      width: 107px !important
      height: 52px !important
      transform: none !important
      @at-root .widget-application--mobile &
        width: 74px !important
        height: 36px !important
      &::before
        background-color: rgba(255, 255, 255, 1) !important
        width: 100% !important
      span
        font-size: 20px !important
        line-height: 28px !important
        font-weight: 400 !important
        margin: 0 4px !important
        @at-root .widget-application--mobile &
          font-size: 13px !important
          line-height: 20px !important
          margin: 0 !important
      img
        width: 20px !important
        height: 20px !important
        @at-root .widget-application--mobile &
          width: 15px !important
          height: 15px !important

.widget-application
  & > .floors-select
    display: none

.floors-select
  position: relative !important
  top: unset !important
  left: unset !important
  display: flex
  align-items: center
  justify-content: center
  height: 52px !important
  background: rgba(255, 255, 255, 1) !important
  border-bottom: none !important
  border-radius: 30px !important
  width: 122px !important
  transform: none !important
  @media (max-width: 670px)
    width: 72px !important
    height: 36px !important
    padding: 8px 16px !important 
  &--text
    font-size: 20px !important
    line-height: 28px !important
    font-weight: 400 !important
    word-spacing: 0 !important
    border-bottom: 0 !important
    padding: 0 !important
    @media (max-width: 670px)
      font-size: 13px !important
      line-height: 20px !important
  &--block
    position: absolute !important
    bottom: 60px !important
    right: 0 !important
    flex-direction: column-reverse !important
    background: rgba(255, 255, 255, 1) !important
    border-radius: 15px 15px 15px 15px !important
    @media (max-width: 670px)
    &-item
      font-size: 20px !important
      line-height: 28px !important
      font-weight: 400 !important
      padding: 14px 22px !important
      word-spacing: 0 !important
      @media (max-width: 670px)
        font-size: 13px !important
        line-height: 20px !important
        padding: 8px 16px !important

